import React from "react"
import Obfuscate from "react-obfuscate"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Données personnelles" />
    <section className="hero pattern-zigzag-light" style={{ marginTop: -4 }}>
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title dreamland is-size-2">Nous contacter</h1>
        </div>
      </div>
    </section>
    <section>
      <div className="container is-fluid">
        <div className="columns is-vcentered mt-5">
          <div className="column">
            <article className="box" style={{ background: "#ffff76" }}>
              <h2 className="is-size-3 almond mb-5">Contacter l'association</h2>
              <p className="is-italic">
                Vous avez une question sur les adhésions, le fonctionnement de
                l'association, etc. ?
              </p>
              <h3 className="almond is-size-4">Serge Grass, président</h3>
              <p>
                ✉ <Obfuscate email="sg44@free.fr" />
              </p>
            </article>
          </div>
          <div className="column">
            <article className="box" style={{ background: "#ffff76" }}>
              <h2 className="is-size-3 almond mb-5">
                Contacter le producteur qui livre vos commandes
              </h2>
              <p className="is-italic">
                Vous avez une demande concernant votre commande ?
              </p>
              <h3 className="almond is-size-4">La ferme du Rondeau</h3>
              <p className="is-italic">
                ✆ <Obfuscate tel="03 81 59 25 84" />
                <br />✉ <Obfuscate email="contact@ferme-rondeau.fr" />
                <br />
                <a href="https://www.ferme-rondeau.fr/">www.ferme-rondeau.fr</a>
              </p>
              <h3 className="almond is-size-4">La ferme de They</h3>
              <p>
                ✆ <Obfuscate tel="03 84 91 81 33" />
                <br />✆ <Obfuscate tel="07 87 50 13 68" />
                <br />✉ <Obfuscate email="theybio70@gmail.com" />
                <br />
                <a href="https://www.fermebiothey.fr">www.fermebiothey.fr</a>
              </p>
              <h3 className="almond is-size-4">AFSAME - Les jardins bio des monts de Gy</h3>
              <p>
                ✆ <Obfuscate tel="06 73 60 72 61" />
                <br />✉ <Obfuscate email="ea@afsame.fr" />
              </p>
              <h3 className="almond is-size-4">Le Cabas de la Planée</h3>
              <p>
                ✆ <Obfuscate tel="06 33 43 92 59" />
                <br />✉ <Obfuscate email="contact@mentheetmelisse.fr" />
              </p>
            </article>
          </div>
          <div className="column">
            <article className="box" style={{ background: "#ffff76" }}>
              <h2 className="is-size-3 almond mb-5">
                Contacter l'assistance technique
              </h2>
              <p className="is-italic">
                Vous rencontrez des difficultés à utiliser le site Web ?
              </p>
              <h3 className="almond is-size-4">Société Aprogsys</h3>
              <p>
                ✉ <Obfuscate email="bko@aprogsys.com" />
                <br />
                <a href="https://www.aprogsys.com">www.aprogsys.com</a>
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage
